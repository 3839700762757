import {defineStore} from 'pinia';

export const GenresStore = defineStore({
    id: 'genresStore',
    state: () => ({
        genres: {
            "nobody": "Noone",
        },
    }),
    actions: {
        setGenres(genres: object[]) {
            // @ts-ignore
            this.genres = genres;
        },
        getGenre(id: string): object {
            // @ts-ignore
            return this.genres[id];
        }
    },
    getters: {
        getGenres(): object[] {
            // @ts-ignore
            return this.genres;
        }
    },
    persist: {
        enabled: true,
        strategies: [
            {
                key: 'flxGenres',
                storage: localStorage,
            }
        ]
    }
});