import { defineStore } from "pinia";

export interface AssetInterface {
  id: string;
  slug: string;
  urlplay: string;
  year: string;
  length: string;
  duration: string;
  downloadable: boolean;
  seconds: string;
  fav: boolean;
  like: null;
  type: string;
  trailer: string;
  country: string;
  title: string;
  title_logo: null;
  synopsis: string;
  message: null;
  short_synopsis: string;
  poster: null;
  subs_es: null;
  subs_en: null;
  tv_rating: string;
  screenshot: null;
  orizon_desktop: null;
  orizon_mobile: null;
  website: null;
  actors: string[];
  director: string[];
  genres: string[];
  current: {
    episode_id: string;
    episode: string;
    serie_id: string;
    title: null;
    description: null;
    img: null;
    urlplay: string;
    sub_es: string;
    sub_en: string;
    season: string;
    next: string;
    next_show_button: string;
    next_jump_to: string;
    next_count_down: string;
    skipintro_show_button: string;
    skipintro_hide_button: string;
    skipintro_jump_to: string;
    duration: string;
    seconds: string;
    preview: string;
    subs_en: string;
    subs_es: string;
  };
  downlodeable: boolean;
  rating: null;
  seasons: any[];
  preview: string;
  trailers_extras: {
    title: string;
    url: string;
    type: string;
    thumbnail: null;
  }[];
}

export const AssetStore = defineStore({
  id: "assetStore",
  state: () => ({
    assets: {
      default: {},
    },
    language: "es",
  }),
  actions: {
    updatePosition(id: string, seconds: number) {
      // @ts-ignore
      this.assets[id].seconds = seconds.toString();
    },
    setAssets(a: object, lang: string) {
      this.language = lang;
      this.assets = Object.assign({}, this.assets, a);
    },
    setAsset(id: string, asset: AssetInterface) {
      console.log("updateing asset");
      // @ts-ignore
      this.assets[id] = asset;
    },
    getAssetData(id: string): AssetInterface {
      // @ts-ignore
      return this.assets[id];
    },
    getAssetBySlug(slug: string): AssetInterface {
      // @ts-ignore
      return Object.values(this.assets).find((asset) => asset.slug === slug);
    },
  },
  getters: {
    getLanguage(): string | null {
      return this.language;
    },
    getAssets(): object {
      return this.assets;
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "flxAssets",
        storage: localStorage,
      },
    ],
  },
});
